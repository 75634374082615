import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

import './Navbare.css';
import LanguageIcon from '@mui/icons-material/Language';

const Navbar = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global"); // Initialize the translation hook
  const [nav, setNav] = useState(false);
  const [isConverted, setIsConverted] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  // const toggleLanguage = () => {
  //   const newLanguage = i18n.language === 'en' ? 'fr' : 'en';
  //   i18n.changeLanguage(newLanguage).then(() => {
  //     console.log(`Language changed to ${newLanguage}`);
  //   });
  // };
  // const handlechangelanguage = (lang) => {
  //   i18n.changeLanguage(lang)
  //     .then(() => {
  //       console.log(`Language changed to ${lang}`);
  //     })
  //     .catch((error) => {
  //       console.error('Error changing language:', error);
  //     });
  // };

  // const handleClick = () => {
  //   if (isConverted) {
  //     i18n.changeLanguage(lang)
  //     .then(() => {
  //       console.log(`Language changed to ${lang}`);
  //     })
  //     .catch((error) => {
  //       console.error('Error changing language:', error);
  //     });
  //       } else {
  //     console.log('Button is clicked!');
  //   }
  //   setIsConverted(!isConverted);
  // };
  // const handleClick = () => {
  //   if (isConverted) {
  //     i18n.changeLanguage('ar') // Change to your desired language code
  //       .then(() => {
  //         console.log(`Language changed to ${i18n.language}`);
  //       })
  //       .catch((error) => {
  //         console.error('Error changing language:', error);
  //       });
  //   } else {
  //     console.log('Button is clicked!');
  //   }
  //   setIsConverted(!isConverted);
  // };
  // const handleClick = () => {
  //   const newLanguage = isConverted ? 'en' : 'ar'; // Toggle between 'en' (English) and 'ar' (Arabic) or your desired language codes
  //   i18n.changeLanguage(newLanguage)
  //     .then(() => {
  //       console.log(`Language changed to ${newLanguage}`);
  //     })
  //     .catch((error) => {
  //       console.error('Error changing language:', error);
  //     });
  //   setIsConverted(!isConverted);
  // };
  const handleClick = () => {

    const newLanguage = isConverted ? 'ar' : 'en'; // Toggle between 'en' and 'ar'
    i18n.changeLanguage(newLanguage)
      .then(() => {
        console.log(`Language changed to ${newLanguage}`);
      })
      .catch((error) => {
        console.error('Error changing language:', error);
      });
    setIsConverted(!isConverted);
    closeNav();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white relative z-10'>
      <div style={{ display: 'flex' }}>
        <h1 className='text-3xl font-bold text-[#00df9a]'>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="images/loba.png"
              alt="Description of the image"
              className="mr-4"
              style={{ height: 'auto', width: '200px' }}
            />
          </Link>
        </h1>
        <h1 className='text-3xl font-bold text-[#00df9a]'>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="images/345.png"
              alt="Description of the image"
              className="mr-4"
              style={{ height: 'auto', width: '150px' }}
            />
          </Link>
        </h1>
      </div>

      <ul className='hidden md:flex'>
        <li className='p-4'>
          <Link to="/automation" style={{ color: 'white' }}>
            {t("Navbar.Solutions")}
          </Link>
        </li>
        <li className='p-4'>
          <Link to="/support" style={{ color: 'white' }}>
            {/* {t('support')}  */}
            {t("Navbar.Support")}
          </Link>
        </li>
        <li className='p-4'>
          <Link to="/contact_us" style={{ color: 'white' }}>
            {/* Contact&nbsp;Us */}
            {t("Navbar.Contact")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/Latestnews" style={{ color: 'white' }}>
            {t("Navbar.News")}
          </Link>
        </li>
        <li className='p-4'>
          <Link to="/ourclient" style={{ color: 'white' }}>
            {t("Navbar.Client")}

          </Link>
        </li>


        {/* <li className='p-4'>
          <Link to={user ? "/Purchase" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Shop")}

          </Link>
        </li> */}
        <li className='p-4'>
          <button className={`buttontr ${isConverted ? 'blackButton' : ''}`} onClick={handleClick}>
            <LanguageIcon /> {isConverted ? 'Arb' : 'Eng'}
          </button>
        </li>

      </ul>

      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-20' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>
          <Link to="/" onClick={closeNav}>
            <img
              src="images/loba.png"
              alt="Description of the image"
              className="mr-4"
              style={{ height: 'auto', width: '200px' }}
            />
          </Link>
        </h1>
        <li className='p-4'>
          <Link to="/automation" style={{ color: 'white' }} onClick={closeNav}>

            {t("Navbar.Solutions")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/support" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Support")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/contact_us" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Contact")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/Latestnews" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.News")}

          </Link>
        </li>
        <li className='p-4'>
          <Link to="/ourclient" style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Client")}
          </Link>
        </li>
        {/* <li className='p-4'>
          <Link to={user ? "/Purchase" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
            {t("Navbar.Shop")}

          </Link>
        </li> */}

        <div>
          <li className='p-4'>
            <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}
              onClick={handleClick}>
              <LanguageIcon /> {isConverted ? 'Arb' : 'Eng'}
            </button>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
