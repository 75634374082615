

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "./Details.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Details = () => {
  const location = useLocation();
  const { data, type } = location.state;
  const [results, setResults] = useState([]);
  const db = firebase.firestore();

  useEffect(() => {
    if (type && data) {
      db.collection(type)
        .where("name", "==", data)
        .get()
        .then((querySnapshot) => {
          const documents = [];
          querySnapshot.forEach((doc) => {
            documents.push(doc.data());
          });
          setResults(documents);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    }
  }, [data, type, db]);

  return (
    <div className="container">
      <div className="product-grid">
        {results.map((result, index) => (
          <ProductDetails key={index} result={result} />
        ))}
      </div>
    </div>
  );
}

const ProductDetails = ({ result }) => (
  // <div className="product-details">
  //   <table className="product-table">
  //     <tbody>
  //       <tr>
  //         <td></td>
  //         <td><b>{result.name}</b></td>
  //       </tr>
  //       <tr>
  //         <td><b>Price:</b></td>
  //         <td>${result.price}</td>
  //       </tr>
  //       <tr>
  //         <td>Data Sheet:</td>
  //         <td className="data-sheet-cell">
  //           {result.data_sheet && (
  //             <button className="data-sheet-button">
  //               <a href={result.data_sheet} target="_blank" rel="noopener noreferrer">
  //                 Data Sheet
  //               </a>
  //             </button>
  //           )}
  //         </td>
  //       </tr>
  //       <tr>
  //         <td>Kind:</td>
  //         <td>{result.kind}</td>
  //       </tr>


  //       <tr>
  //         <td colSpan="2" style={{ textAlign: 'center' }}>Description</td>
  //       </tr>
  //       <tr>
  //         <td className="ltr" colSpan="2"><p>{result.description}</p></td>
  //       </tr>
  //     </tbody>
  //   </table>
  //   {result.image1 && <img className="product-image" src={result.image1} alt="Product" />}
  // </div>
  <div className="product-container">
    <div className="product-detailse">
      <p className="nametxt">{result.name}</p>
      <p className="nametxt">Price: ${result.price}</p>

      {result.data_sheet && (
        <a
          href={result.data_sheet}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary"
        >
          Data Sheet
        </a>
      )}

      <div className="product-info">
        <p>
          <strong>Kind:</strong> {result.kind}
        </p>
        <p>
          <strong>Description:</strong> {result.description}
        </p>
      </div>
    </div>

    {result.image1 && (
      <img className="product-image" src={result.image1} alt="Product" />
    )}
  </div>

);

export default Details;
