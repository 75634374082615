import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./Support.css";


const titleStyle = {
    fontSize: '2em',  // Adjust the size as needed
    color: '#333',    // Set your preferred text color
    fontFamily: 'Arial, sans-serif',  // Specify a friendly font-family
};
const Support = () => {
    const { t, i18n } = useTranslation("global");

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    return (
        <div className="Supportcontainer" >
            <header>
                <h1 style={titleStyle}>{t("support.Supporttitle")}</h1>
                <br />
                <br />
                <br />
            </header>
            <div className="supportB" style={{ direction: getDirection() }} >
                <div className="friendly-coverSupport" style={{ direction: getDirection() }}>
                    {t("support.message1") }
                    <br />
                    {t("support.message2")}
                </div>
            </div>
            <div >
                <br />
                <br />
                <div className="support-text">
                    {t("support.subtittel")}
                </div>
            </div>
            <div class="SupportG-container">
                <div class="SupportG-item test">
                    <img

                        src="assets/save.jpg"
                        alt="save"
                        className="img-fluid"
                    />
                </div>
                <div class="SupportG-item test"> <br />
                    <h1><b>{t("support.EMERGENCY")}</b><br />
                    </h1>
                    <p className="coloreP">
                        {t("support.EMERGENCYTXT")}
                    </p>
                </div>
                <div class="SupportG-item testt ">
                    <br />   <b> <h1>{t("support.HARDWARETITTEL")}</h1></b> <br />
                    <p className="coloreP">
                        {t("support.HARDWARE1")}<br />
                        {t("support.HARDWARE2")} <br />
                        {t("support.HARDWARE3")}<br />
                        {t("support.HARDWARE4")}<br />
                        {t("support.HARDWARE5")}<br />
                        {t("support.HARDWARE6")}<br />
                        {t("support.HARDWARE7")}<br />
                        {t("support.HARDWARE8")}<br />
                        {t("support.HARDWARE9")}<br />
                    </p>
                </div>
                <div class="SupportG-item testt">
                    <img
                        src="assets/run.jpg"
                        alt="run"
                        className="img-fluid"
                    />
                </div>
                <div class="SupportG-item testtt">
                    <img
                        src="assets/rasme.jpg"
                        alt="fanen"
                        className="img-fluid"
                    />
                </div>
                <div class="SupportG-item testtt"><br />
                    <b><h1>
                        {t("support.ON_SITE_SUPPORT_INSTALLATION")}
                    </h1></b>
                    <br />
                    <p className="coloreP">
                        {t("support.ON_SITE_SUPPORT_INSTALLATION_TXT")}

                    </p>
                </div>
                <div class="SupportG-item">
                    <br />    <b>  <h1>
                        {t("support.SUPPORT_24_7_TITLE")}
                    </h1></b>  <br />
                    <p> {t("support.SUPPORT_24_7_TEXT")}
                    </p>
                </div>
                <div class="SupportG-item"><img
                    src="assets/hour.jpg"
                    alt="fanen"
                    className="img-fluid"
                /></div>

            </div>
        </div>
    );
};
export default Support;