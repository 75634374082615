import { sendSignInLinkToEmail } from "firebase/auth";
// import { auth } from "./firebase";
import { issueMsg } from "../common/issueMsg";
import { auth } from "./firebase";


const sendEmailLink = async (email) => {


  const REDIRECT_URL_LOGIN = process.env.REACT_APP_REDIRECT_URL_LOGIN;

  const actionCodeSettings = {
    url: REDIRECT_URL_LOGIN,
    handleCodeInApp: true,
  }


  try {
    await sendSignInLinkToEmail(auth,email,actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
    issueMsg('An email has been sent for email verification.');

  } catch(error) {
    console.log(error);
    issueMsg('Failed to send the email for Email authentication.',error.code);
  }
}

export default sendEmailLink;
