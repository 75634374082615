import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  } from "firebase/auth";

const getCredential = async (type, user, pass = null) => {
  let credential;
  if (type === "email") {
    let password;
    pass
      ? (password = pass)
      : (password = prompt("Please enter the password"));
    if (!password) {
      return false;
    }
    credential = EmailAuthProvider.credential(user.email, password);

  } else if (type === "google") {


  } else {
    console.log("Invalid authentication information request");
    return false;
  }
  await reauthenticateWithCredential(user, credential);
  return true;
};

export default getCredential;
