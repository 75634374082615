import React, { useEffect, useState } from "react";
import "./Contact_Us.css";
import { BsWhatsapp, BsGeoAlt } from "react-icons/bs";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { CiMobile1 } from "react-icons/ci";
import { useTranslation } from 'react-i18next';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);

const Contact_Us = () => {

    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
       useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    
   
  

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
        // Call your function to add information to Firestore here
        await addInformation();
    };

    const whatsappNumber = "+15073131273";
    const whatsappMessage = "Hello, I have a question.";
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
    const locationLink = `https://www.google.com/maps/place/...`; // Your location link
    const call = "tel: +966122230643";

    const addInformation = async () => {
        try {
            const collectionRef = collection(firestore, 'Contact');
            await addDoc(collectionRef, formData);
            console.log('Information added successfully!');
            // Optionally, reset the form data after submission
            setFormData({
                name: "",
                email: "",
                message: "",
            });
        } catch (error) {
            console.error('Error adding information:', error);
        }
    };

    return (
        <div className="contact-us-container"  style={{ direction: getDirection() }}>
            <header>
                <h1 style={{ fontSize: '2em', color: '#333', fontFamily: 'Arial, sans-serif' }}>
                {t("Contact.Contacttitle")}
                </h1>
                <br />
                <br />
                <br />
            </header>
            <div>
                <div className="contactn with-backgroundC" >
                    <div className="friendly-cover" >
                    {t("Contact.Contactpart1") } <br /> {t("Contact.Contactpart2") }
                    </div>
                </div>
            </div>
            <div>
                <br />
                <div></div>
            </div>
            <div>
                <div className="col-md-6">
                    <div className="col-md-6"></div>
                    <div className="contact contact-info">
                        <div className="contact">
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="name">{t("Contact.name") }</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="email">{t("Contact.mail") }</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                                <label htmlFor="message">{t("Contact.message") }</label>
                                
                                <textarea
                                    id="message"
                                    name="message"
                                    rows="4"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                >

                                </textarea>
                                <button type="submit" className="btnh">{t("Contact.buttons") }</button>
                            </form>
                        </div>
                        <p>
                        {t("Contact.mapInfo") }
                        </p>
                        <p>+966 12 223 0643</p>
                        <p>+966 55 608 0505</p>
                        <a href="mailto:info@jasotech.com">info@jasotech.com</a>
                        <div className="follow-us">
                            <a href={whatsappLink} className="icon-link">
                                <BsWhatsapp className="fs-3" />
                            </a>
                            <a href={locationLink} className="icon-link">
                                <BsGeoAlt className="fs-3" />
                            </a>
                            <a href={call} className="icon-link">
                                <CiMobile1 className="fs-3" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>
                <div>
                    <div className="row">
                        <div>
                            <img
                                src="assets/handshake.jpg"
                                alt="Handshake"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact_Us;
