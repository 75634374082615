import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
if (process.env.NODE_ENV === 'development') {
  console.log('Firebase App:', app);
}

// export { auth, googleProvider, db };

// export { auth, googleProvider, db, app, analytics, getFirestore };
export { auth, googleProvider, db, app, analytics, collection, getDocs, firebaseConfig}; // Export collection and getDocs

// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore, collection, getDocs } from "firebase/firestore";
// import { GoogleAuthProvider } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";
// import axios from 'axios'; // Import Axios

// // Fetch the Firebase configuration using Axios
// const fetchFirebaseConfig = async () => {
//     try {
//         const response = await axios.get('http://localhost:8000/firebase-config'); // Update the URL as needed
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching Firebase config:', error);
//         throw error;
//     }
// };

// // Use an async function to fetch the config before initializing Firebase
// const initializeFirebase = async () => {
//     const firebaseConfig = await fetchFirebaseConfig();

//     const app = initializeApp(firebaseConfig);
//     const analytics = getAnalytics(app);
//     const auth = getAuth(app);
//     const db = getFirestore(app);
//     const googleProvider = new GoogleAuthProvider();

//     if (process.env.NODE_ENV === 'development') {
//         console.log('Firebase App:', app);
//     }

//     return { auth, googleProvider, db, app, analytics, collection, getDocs, firebaseConfig };
// };

// export default initializeFirebase;
