import React from "react";
import Sketch from "./Sketch";
import Slideshow from "./Slideshow";
import Solution from "./Solution";
import About from "./About";

const Home = () => {
  return (
    <div>
      <section style={{ position: "relative" }}>
        <div className="Hocontainer-xxl">
          <div className="Horow">
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -2
            }}>
              <Sketch />
            </div>
            <div >
              <Slideshow />
            </div>
            <div >
              <Solution />
            </div>
            <div >
              <About />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
