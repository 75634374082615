import React, { useEffect } from "react";
import "./Safety.css";
import Headsol from "./Headsol";
import { useTranslation } from 'react-i18next';

const Safety = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    return (
        <div>
            <div>
                <Headsol />
            </div>
            <div>

                <h1 className="titleh"  style={{ direction: getDirection() }}>{t("safety.title")}</h1>
                <br />
                <br /> <br />
                <br />

                <h3 className="titlem" style={{ direction: getDirection() }}>{t("safety.fireAlarm")}</h3>
                <br />
                <br />
                <div>
                    <div className="col-md-6">
                        <div className="automationtxt colmd6">

                            <p style={{ color: 'black' ,direction: getDirection() }}>
                                {t("safety.fireAlarmDescription1")}
                                {t("safety.fireAlarmDescription2")}
                                <br /><br /><br />
                                {t("safety.fireAlarmDescription3")}
                                <br /><br />
                                {t("safety.fireAlarmDescription4")}
                                {t("safety.fireAlarmBenefits1")}
                                {t("safety.fireAlarmBenefits2")}
                                <br /><br /><br />
                                {t("safety.fireAlarmExpandingSolutions1")}
                                {t("safety.fireAlarmExpandingSolutions2")}
                                <br /><br /><br />
                           
                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/fire-alarms-system.jpg"
                            alt={t("safety.fireAlarmAltText")}
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>

            <div>
                <br />
                <br /> <br />
                <br />
                <h3 className="titlem"  style={{ direction: getDirection() }}>{t("safety.publicAddress")}</h3>
                <br />
                <br />
                <div>
                    <div className="col-md-6">
                        <div className="automationtxt colmd6">

                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("safety.publicAddressDescription1")}
                                <br /><br /><br />
                                {t("safety.publicAddressDescription2")}
                                {t("safety.publicAddressDescription3")}
                                <br /><br />
                                {t("safety.publicAddressDescription4")}
                                {t("safety.publicAddressAudioSystem")}
                                <br /><br />
                                {t("safety.publicAddressAudioSystemDescription")}
                                {t("safety.publicAddressAudioInstallation")}
                                <br /><br /><br />
                                {t("safety.publicAddressAudioInstallationDescription")}
                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/Removal-114.png"
                            alt={t("safety.publicAddressAltText")}
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>

            <div>
                <br />
                <br /> <br />
                <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>{t("safety.masterClockSystem")}</h3>
                <br />
                <br />
                <div>
                    <div className="col-md-6">
                        
                        <div className="automationtxt colmd6">
                            <p style={{ color: 'black', direction: getDirection()  }}>
                                {t("safety.masterClockSystemDescription1")}
                                <br /><br /><br />
                                {t("safety.masterClockSystemDescription2")}
                              
                                {t("safety.masterClockSystemFunction")}
                                <br /><br />
                                {t("safety.masterClockSystemTypicalEquipment")}
                                <br /><br /><br />
                                {t("safety.masterClockSystemAdoption")}
                                <br /><br /><br />
                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/clockIM.jpg"
                            alt={t("safety.masterClockSystemAltText")}
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>
        </div>
    );
};

export default Safety;
