import React, { useEffect, useState } from "react";
import "./Automation.css";
import Headsol from "./Headsol";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

const Automation = () => {
    const { t, i18n } = useTranslation("global");


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div  >
            <div >
                <Headsol />
            </div>
            <div>
                <h1 className="titleh" style={{ direction: getDirection() }}>
                    {t("automation.title")}
                </h1>
                <br />
                <br />  <br />
                <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>
                    {t("automation.buildingManagementSystem")}
                </h3>

                <br />
                <br />

                <div  >
                    <div className="col-md-6">
                        <div className="automationtxt colmd6">
                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("automation.BuildingTXT1")}
                                {t("automation.BuildingTXT2")}
                                <br /><br /><br />
                                {t("automation.BMSDescription1")}
                                <br /><br />
                                {t("automation.BMSDescription2")}
                                <br /><br /><br />
                                {t("automation.Benefits1")}
                                <br /><br />
                                {t("automation.Benefits2")}
                                <br /><br /><br />
                                {t("automation.ExpandingSolutions1")}
                                
                                {t("automation.ExpandingSolutions2")}
                                {t("automation.SystemsExtend1")}
                                <br /><br />

                            </p>

                        </div>
                    </div>

                    <div className="colmd6">
                        <img
                            src="assets/bms-banner-img.jpg"
                            alt="bulding managment System"
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>

                </div>

            </div>

            <div>
                <br />
                <br />
                <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>{t("automation.audiovisualsTitle")}</h3>

                <br />
                <br />

                <div>
                    <div className="col-md-6">
                        <div className="automationtxt colmd6">
                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("automation.AudioVisualsDescription1")}
                               {t("automation.AudioVisualsDescription2")}
                                
                               <br /> <br /> <br /> {t("automation.AudioVisualsDescription3")}
                               
                                <br /> {t("automation.AudioVisualsDescription4")}
                                
                                {t("automation.StrategicCollaborations")}
                                <br /> 
                                <br />
                                <br /> {t("automation.PremiumAVProducts1")}
                                
                                {t("automation.PremiumAVProducts2")}
                             
                                {t("automation.CorporateBoardroomsExample")}
                                <br />
                                <br />
                                <br />
                                <br /> {t("automation.EducationalSettingsExample")}
                                 <br /> {t("automation.PublicSpacesExample")}
                                <br />
                                <br />
                                <br />
                                <br />
                        
                            </p>
                        </div>
                    </div>

                    <div className="colmd6">
                        <img
                            src="assets/Audio-Visual-Control-Systems.jpg"
                            alt="switch"
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>

        </div>

    );
};

export default Automation;
