import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';
import {
  BsGeoAlt,
   BsWhatsapp
} from "react-icons/bs";
import { Link } from 'react-router-dom';
import { CiMobile1 } from "react-icons/ci";

const Footer = () => {
  const whatsappNumber = "+15073131273";
  const whatsappMessage = "Hello, I have a question.";

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
  const locationLink = `https://www.google.com/maps/place/21%C2%B031'28.1%22N+39%C2%B010'51.4%22E/@21.5245492,39.1809318,20.76z/data=!4m4!3m3!8m2!3d21.5244779!4d39.1809357?hl=en&entry=ttu`;
  const call ="tel: +966122230643" ;

  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#297fb9]'>Let's Connect.</h1>
        <p className='py-4   text-white '>    Sign up to receive exclusive communications.</p>
        <div className='flex justify-between md:w-[75%] my-6'>

          <a className="text-white" href={whatsappLink}>
            <BsWhatsapp size={30} />
          </a>
          <a className="text-white" href={locationLink}>
            <BsGeoAlt size={30} />
          </a>
          <a className="text-white" href={call}>
            <CiMobile1 size={30} />
          </a>
        </div>
      </div>
      <div className='lg:col-span-2 flex flex-col lg:flex-row justify-between'>
        <div className='mb-8 lg:mb-0'>
          <h6 className='font-medium text-blue-400'>Solutions</h6>
          <ul className='mt-2 lg:mr-8'>
            <Link to="/automation" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>Automation</li>

            </Link>
            <Link to="/safety" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>Safety</li>

            </Link>
            <Link to="/security" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>Security</li>

            </Link>
            <Link to="/connectivity" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>Connectivity</li>

            </Link>
          </ul>
        </div>
        <div className='mb-8 lg:mb-0'>
          <h6 className='font-medium text-blue-400'>Service</h6>
          <ul className='mt-2 lg:mr-8'>
            <Link to="/support" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>SUPPORT</li>

            </Link>
            <Link to="/Latestnews" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>NEWS</li>

            </Link>
            <Link to="/contact_us" style={{ color: 'white' }}>
              <li className='py-2 text-sm'>CONTACT</li>

            </Link>
            {/* <Link to="/shop" style={{ color: 'white' }}>
            <li className='py-2 text-sm'>SHOP</li>

            </Link> */}
          </ul>
        </div>
        <div>
          <h6 className='font-medium text-blue-400'>Contact Us</h6>
          <ul className='mt-2'>
            <li className='py-2 text-sm'>
              <a className="text-white"
                href="https://www.google.com/maps?q=21.5244779,39.1809357&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gulf Plaza
                <br />   7571 Al Madinah Al Munawarah Rd.
                <br />   Ash Sharafiyah District
                <br />  1st Floor, Suite No. 13
                <br />  Jeddah, Saudi Arabia
              </a>
            </li>
            <li className='py-2 text-sm'>
              <a href="tel: +966122230643" className="text-white">
                +966 12 223 0643
              </a>
            </li>
            <li className='py-2 text-sm'>
              <a href="mailto: info@jasotech.com" className="text-white">
                info@jasotech.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;