
// const SendOrder = async () => {
//   const element = document.getElementById('pdf-content');
//   if (element) {
//     html2canvas(element).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust the dimensions as needed
//       pdf.save('generated-pdf.pdf');
//     });
//   } else {
//     console.error("Element with id 'pdf-content' not found");
//   }
//   try {
//     const ordersCollection = db.collection('orders');
//     await ordersCollection.add({
//       userId: user.uid,
//       userName: formData.name,
//       userEmail: user.email,
//       userPhoneNumber: formData.phoneNumber,
//       userGender: formData.gender,
//       userBirthdate: formData.birthdate,
//       cartItems: cartItems.map(item => ({ id: item.id, quantity: item.quantity })),
//       totalQuantity,
//       totalPrice,
//       mapUrl: MapUrl,
//     });

//     console.log('Order data uploaded successfully!');
//   } catch (error) {
//     console.error('Error uploading order data:', error);
//   }




// };

// <div id="pdf-content">
//   <div className="pdf-header">
//     <h1>Your Company Name</h1>
//     <p>Order Invoice</p>
//   </div>

//   <h2>Order Details</h2>
//   <table>
//     <thead>
//       <tr>
//         <th>Product Name</th>
//         <th>Price</th>
//         <th>Quantity</th>
//         <th>Subtotal</th>
//       </tr>
//     </thead>
//     <tbody>
//       {cartItems.map((item, index) => (
//         <tr key={index}>
//           <td>{item.name}</td>
//           <td>${parseFloat(item.price).toFixed(2)}</td>
//           <td>{item.quantity}</td>
//           <td>${(parseFloat(item.price) * item.quantity).toFixed(2)}</td>
//         </tr>
//       ))}
//     </tbody>
//   </table>

//   <div className="pdf-footer">
//     <p>Total Quantity: {totalQuantity}</p>
//     <p>Total Price: ${totalPrice.toFixed(2)}</p>
//     <p>Thank you for your purchase!</p>
//   </div>
// </div>
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./Userpdf.css";
import { useAuthContext } from "../context/AuthContext";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const Userpdf = () => {
  const location = useLocation();
  const { cartItems } = location.state;
  const { user } = useAuthContext();
  const [MapUrl, setMapUrl] = useState(null);
  const [userData, setUserData] = useState(null); // State to store user data
  const [isEditing, setIsEditing] = useState(true); // Declare setIsEditing state
  const [isConfirmed, setIsConfirmed] = useState(false); // Added state for confirmation

  useEffect(() => {
  }, [cartItems]);
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       setCurrentLocation({ latitude, longitude });
  //       setMapUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
  //     },
  //     (error) => {
  //       console.error('Error getting location:', error.message);
  //     }
  //   );
  // }, []);
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",

  });
  const db = firebase.firestore();
  const userCollection = db.collection('users');
  const UploadData = async () => {
    try {
      const userId = user.uid;
      const userDocRef = userCollection.doc(userId);
      await userDocRef.set({
        name: formData.name,
        email: user.email,
        phoneNumber: formData.phoneNumber,

        // cartItems: cartItems.map(item => ({ id: item.id, quantity: item.quantity })),
        // totalQuantity,
        // totalPrice,
        // MapUrl: MapUrl,
      });
      setIsEditing(false); // Disable editing after confirming
      setIsConfirmed(true); // Set confirmation status to true

      // console.log('Data uploaded successfully!');
    } catch (error) {
      // console.error('Error uploading data:', error);
    }
  };

  const SendOrder = async () => {

    try {
      const ordersCollection = db.collection('orders');
      await ordersCollection.add({
        userId: user.uid,
        userName: formData.name,
        userEmail: user.email,
        userPhoneNumber: formData.phoneNumber,

        cartItems: cartItems.map(item => ({ id: item.id, quantity: item.quantity })),
        totalQuantity,
        totalPrice,
        mapUrl: MapUrl,
      });

      // console.log('Order data uploaded successfully!');
    } catch (error) {
      // console.error('Error uploading order data:', error);
    }
  };

  const handleConfirmButtonClick = () => {
    setIsEditing(false); // Disable editing after confirming
    UploadData(); // Upload data after confirmation
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  useEffect(() => {
    // Function to fetch user data from Firestore
    const fetchUserData = async () => {
      try {
        const userId = user.uid; // Assuming user.uid is accessible in this component
        const userDocRef = db.collection('users').doc(userId);
        const doc = await userDocRef.get();
        if (doc.exists) {
          setUserData(doc.data()); // Set the retrieved user data in the state
        } else {
          // console.log('No such document!');
        }
      } catch (error) {
        // console.error('Error fetching user data:', error);
      }
    };

    fetchUserData(); // Call the function to fetch user data
  }, [user.uid]);

  const generatePDF = () => {
    // Ensure that the element with id 'pdf-content' exists in the DOM
    const element = document.getElementById('pdf-content');
    if (element) {
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust the dimensions as needed
        pdf.save('generated-pdf.pdf');
      });
    } else {
      // console.error("Element with id 'pdf-content' not found");
    }
  };
  return (<div className="all-page">
    <div id="pdf-content" className="all-pdf">
      <div className="colmd6">
        <img
          src="assets/header.jpg"
        />
      </div>
      <div id="pdf-content">
        {userData && (
          <div>
            <p>Name: {userData.name}</p>
            <p>Email: {userData.email}</p>
            <p>Phone Number: {userData.phoneNumber}</p>

          </div>
        )}
      </div>
      <table className="user-info-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <tr key={item.id}>
              <td >{item.name}</td>
              <td>${item.price}</td>
              <td>{item.quantity}</td>
              <td>${(parseFloat(item.price) * item.quantity).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>Total Quantity: {totalQuantity}</td>
            <td>Total Price: ${totalPrice.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <div className="colmd6">
        <img
          src="assets/footer.jpg"
        />
      </div>
    </div>
    <button type="button" onClick={generatePDF}>
      get our bill
    </button>
  </div>
  );

};

export default Userpdf;
