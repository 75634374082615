

import React, { useState, useEffect } from 'react';
import './Slideshow.css';
import { useTranslation } from 'react-i18next';

const Slideshow = () => {
  const { t, i18n } = useTranslation("global");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };
  const images = [
    { src: 'assets/Absolute_Reality_v16_We_are_pioneers_in_providing_solutions_t_2 (1).jpg', description: t("Slideshow.desc1") },
    { src: 'assets/Memorial-University-Student-1024x683.jpg', description: t("Slideshow.desc2") },
    { src: 'assets/Default_transformative_experiences_through_the_strategic_appl_1_7b83e648-986f-4aef-800d-068859e303e0_1 (1).jpg', description: ' ' },
    { src: 'assets/Absolute_Reality_v16_By_aligning_with_our_expertise_businesses_0 (1).jpg', description: t("Slideshow.desc3") },
  ];
  

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 20000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="slideshow" style={{ direction: getDirection() }}>
      <div className="cube">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slideshow-card ${currentIndex === index ? 'active' : ''}`}
          >
            <img src={image.src} alt={`Slide ${index + 1}`} className="slideshow-image" />
            <div className="slideshow-description">
              <p >{image.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;






