// Solution.js
import React, { useState, useEffect } from 'react';
import "./Solution.css";
import backgroundImage1 from '../components/images/automation.jpg';
import backgroundImage2 from '../components/images/connectivity.jpg';
import backgroundImage3 from '../components/images/Safety.jpg';
import backgroundImage4 from '../components/images/Security.jpg';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useTranslation } from 'react-i18next';

const Solution = () => {
    const { t, i18n } = useTranslation("global");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    const cards = [
        {


            title: t("Solution.SolutionTitel1"),
            description: t("Solution.description1"),
            backgroundImage: backgroundImage4,
            Link_to: "/security"

        },
        {
            title: t("Solution.SolutionTitel2"),
            description: t("Solution.description2"),
            backgroundImage: backgroundImage1,
            Link_to: "/automation"

        },
        {
            title: t("Solution.SolutionTitel3"),
            description: t("Solution.description3"),
            backgroundImage: backgroundImage2,
            Link_to: "/connectivity"

        },
        {
            title: t("Solution.SolutionTitel4"),
            description: t("Solution.description4"),
            backgroundImage: backgroundImage3,
            Link_to: "/safety"

        },


    ];

    return (
        <div className="solution-wrapper"style={{ direction: getDirection() }}>

            <b> <h1 className="solution-title">{t("Solution.SolutionTitel")}</h1></b>
            <br />
            <br />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="friendly-message" style={{ direction: getDirection() }}>
                {t("Solution.Solutiomessage")}
            </div>
            <div className="solution-container">
                {cards.map((card, index) => (
                    <Link key={index} to={card.Link_to}>
                        <div
                            className="solution-card"
                            style={{
                                backgroundImage: `url(${card.backgroundImage})`,
                                marginRight: index < cards.length - 1 ? '1px' : '0',
                            }}
                        >
                            <div className="card-content">
                                <h2 className="card-title">{card.title}</h2>
                                <p className="card-description">{card.description}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Solution;
