import { mailRegistration } from "../service/firebase/registration";
import { useAuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Title from "./Title";

import { Button, Input } from "@mui/material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SendIcon from "@mui/icons-material/Send";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { useState } from 'react';

import './SignUp.css';

const SignUp = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const [emailInput, passwordInput, confirmPasswordInput] = e.target.elements;

    const email = emailInput.value.trim();
    const password = passwordInput.value.trim();
    const confirmPassword = confirmPasswordInput.value.trim();

    if (password !== confirmPassword  || password.length < 6) {
      console.error("Please make sure your passwords match and are at least 6 characters long.");
      setPasswordMismatch(true);
      return;
    }

    try {      setPasswordMismatch(false);

      const registrationResult = await mailRegistration(email, password);

      if (registrationResult) {
        navigate("/home");
      } else {
        console.error("Registration failed");
      }
    } catch (error) {
      console.error("An unexpected error occurred", error);
    }
  };

  return (
    <div className="signup-container">
      <Title />
      <h2 className="signup-title">SignUp </h2>
      <h3 className="signup-title">{user && user.email}</h3>
      <form className="signup-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">email address: </label>
          <Input
            id="email"
            name="email"
            placeholder="email"
            type="email"
          />
        </div>
        <div>
          <label htmlFor="password">password: </label>
          <Input
            id="password"
            name="password"
            placeholder="password"
            type="password"
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <Input
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm your password"
            type="password"
            required
          />
        </div>
        {passwordMismatch && (
          <p className="error-message">
            <ErrorOutlineIcon style={{ marginRight: "5px" }} />
            Passwords do not match.
          </p>
        )}
        <div>
          <p className="signup-info">Please make the password 6 characters or longer.</p>
          <Button variant="outlined" startIcon={<AppRegistrationIcon />} type="submit" className="signup-btn">
            Registration
          </Button>
        </div>
      </form>
      <Button className="login-btn"

        variant="outlined"
        onClick={() => navigate("/login")}
        style={{ margin: "0 20px" }}
        endIcon={<SendIcon />}
      >
        Login screen
      </Button>
    </div>
  );
};

export default SignUp;
