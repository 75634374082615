// PrivateRoute.js
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
const PrivateRoute = (props) => {
  const { user } = useAuthContext();
  const location = useLocation();

  return (
    <>
      {(
        location.pathname === "/support" ||
        location.pathname === "/Latestnews" ||
         location.pathname === "/login" ||
        location.pathname === "/home" ||/////////////////////////////////////////////////
        location.pathname === "/contact_us" ||
        location.pathname === "/automation" ||
        location.pathname === "/safety" ||
        location.pathname === "/security" ||
        location.pathname === "/connectivity" ||
        location.pathname === "/liscence" ||
        location.pathname === "/cabel" ||
        location.pathname === "/phone" ||
        location.pathname === "/switch" ||
        location.pathname === "/controlunit" ||
        location.pathname === "/article" ||
        location.pathname === "/" ||////////////////fine fut 3l home page lama kun mn 3amet lsa3eb
        location.pathname === "/details" ||
        location.pathname === "/AuthPage" ||
        location.pathname === "/dashboard" ||
        location.pathname === "/product" ||
        location.pathname === "/ourclient" || 
        location.pathname === "/forget" || 
        location.pathname === "/Purchase" || 
        location.pathname === "/signup" || 
        location.pathname === "/user-info" ||

        // location.pathname === "/continue" || 
       
        // location.pathname === "/shoppingcart" || 
        // location.pathname === "/continue" || 
        // location.pathname === "/SwitchList" ||
        // location.pathname === "/CabelList" ||
        // location.pathname === "/phonelist" ||

        location.pathname === "/home" ||user) ? (
        <Outlet {...props} />
      ) : (
        <Navigate to="/login" replace />
      )} 
    </>
  );
};

export default PrivateRoute;
