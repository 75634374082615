import React, { useState, useEffect } from 'react';
import P5Wrapper from 'react-p5';
import "./Sketch.css";

const Sketch = () => {
  let points = [];
  let travelers = [];
  let cnt, d, p, c, r, a, b, t, d2, s, rd, gr, bl, x, y;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(canvasParentRef);
    // Change background color to transparent black
    p5.background(0, 0, 0, 0);
    cnt = 0;
    d = 15;
    p = { x: 0, y: 0 };
    points.push(p);
    p5.angleMode(p5.DEGREES);
    c = 0;
    
    // Adjust the number of points based on your preference
    while (c < 5000) {
      r = p5.random(-p5.min(p5.width, p5.height) / 1.5);
      a = p5.random(360);
      p = {
        x: r * p5.cos(a),
        y: r * p5.sin(a),
      };
      c++;
      b = true;
      for (let i = 0; i < points.length; i++) {
        if (p5.dist(p.x, p.y, points[i].x, points[i].y) < d) {
          b = false;
        }
      }
      if (b) {
        cnt++;
        points[cnt] = p;
      }
    }
    
    travelers[0] = {
      x: 0,
      y: 0,
      x1: 0,
      y1: 0,
      r: p5.random(20, p5.width / 4),
      centerX: 0,
      centerY: 0,
      a: 0,
      t: 0,
      speed: 0.1,
      d: 30,
    };
  };

  const draw = (p5) => {
    p5.translate(p5.width / 2, p5.height / 2);
    
    // Clear the background completely
    p5.background(0, 0, 0, 0);
    t = p5.frameCount / 30;
    p5.rotate(t);
    for (let i = 0; i < travelers.length; i++) {
      travelers[i].x = travelers[i].r * p5.cos(travelers[i].a + travelers[i].t);
      travelers[i].y = travelers[i].r * p5.sin(travelers[i].a + travelers[i].t);
      travelers[i].t += travelers[i].speed;
    }
  
    d2 = 20;
    p5.noStroke();
  p5.clear();
    for (let i = 0; i < points.length; i++) {
      p5.fill(155, 155, 155,255);
      // lone  l point
      p5.circle(points[i].x, points[i].y, 2);
    }
  
    for (let i = 0; i < points.length; i++) {
      for (let j = 0; j < travelers.length; j++) {
        s = p5.dist(points[i].x, points[i].y, travelers[j].x, travelers[j].y);
        if (s < travelers[j].d) {
          rd = p5.map(travelers[j].x, -p5.width / 2, p5.width / 2, 50, 200);
          gr = p5.map(travelers[j].y, -p5.width / 2, p5.width / 2, 80, 150);
          bl = p5.map(travelers[j].y, -p5.width / 2, p5.width / 2, 100, 250);
          p5.stroke(97, 218, 251, 255);
          p5.line(points[i].x, points[i].y, travelers[j].x, travelers[j].y);
        }
      }
    }
  };
  
  const mousePressed = (p5) => {
    x = p5.mouseX - p5.width / 2;
    y = p5.mouseY - p5.height / 2;
    if (p5.dist(x, y, 0, 0) < p5.min(p5.width, p5.height) / 2.2) {
      const r = p5.sqrt(x * x + y * y);
      let a;
      if (x >= 0) { 

        a = p5.asin(y / r);
      } else if (x < 0 && y > 0) {

        a = p5.acos(x / r);
      } else {

        a = 180 - p5.asin(y / r); 
      }
      travelers.push({
        x: 0,
        y: 0,
        x1: x,
        y1: y,
        r: r,
        centerX: 0,
        centerY: 0,
        a: a - t,
        t: 0,
        speed: p5.random(-0.5, 0.5),
        d: p5.randomGaussian(d + 15, 10),
      });
    }
  };

  const keyPressed = (p5) => {
    points = [];
    travelers = [];
    setup(p5);
    draw(p5);

  };

  return (
    <>
      {/* Include the SpiderAnimation component */}
      <P5Wrapper setup={setup} draw={draw} mousePressed={mousePressed} />
    </>
  );};

export default Sketch;










 