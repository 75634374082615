import { sendPasswordResetEmail } from "firebase/auth";
import { issueMsg } from "../common/issueMsg";
import { auth } from "./firebase";


const submitPasswordResetEmail = async (email) => {
  const actionCodeSettings = {
     url: process.env.REACT_APP_REDIRECT_URL_LOGIN,
    handleCodeInApp: false,
  }
  try{
    await sendPasswordResetEmail(auth,email,actionCodeSettings);
    issueMsg(`An email for password reset has been sent to ${email}`);
  } catch(error){
    console.log(error.code);
    console.log(error);
    issueMsg('Failed to send the password reset emai。', error.code);
  }



}

export default submitPasswordResetEmail;
