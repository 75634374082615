import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from "./firebase"
import { issueMsg } from "../common/issueMsg";

const mailRegistration = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await sendEmailVerification(auth.currentUser);
    issueMsg("An email for authentication has been sent. Click the URL in the email to authenticate the corresponding email.");
    return true;
  } catch (error) {
    if (error.code === 'auth/invalid-email') {
      issueMsg("Please enter a valid email address format");
    } else if (error.code === 'auth/weak-password') {
      issueMsg("The password must be set to 6 characters or more");
    } else if (error.code === 'auth/email-already-in-use') {
      issueMsg("That email address is already registered");
    } else {
      issueMsg(`An error occurred during registration.${error.code}`);
    }
    console.error(error);
    return false;
  }
}

export { mailRegistration } ;
