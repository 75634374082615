


import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./App.css";
import Footer from './components/Footer.js';
import Navbare from './components/Navbare';
import Homee from "./pages/Homee";
import SignUp from "./components/SignUp";
import { AuthProvider } from "./context/AuthContext";
import Home from "./components/Home"; 

import { useEffect, useState } from 'react';
import axios from 'axios';

import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import Solutions from "./components/Solutions";
import Automation from "./components/Automation";
import Safety from "./components/Safety";
import Security from "./components/Security";
import Connectivity from "./components/Connectivity";
import Support from "./components/Support";



import Contact_Us from "./components/Contact_Us";
import LatestNews from "./components/LatestNews";
import Liscence from "./components/Liscence";
import Cabel from "./components/Cabel";
import Phone from "./components/Phone";
import Switch from "./components/Switch";
import Controlunit from "./components/Controlunit";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { MdSupportAgent } from "react-icons/md";

import Article from "./components/Article";
import Ourclient from "./components/Ourclient";
import Details from './Details';
import SignUpPage from './SignUpPage';
import Purchase from './Purchase';
import LogForgetpassword from "./components/LogForgetpassword";
import UserInfoPage from "./components/UserInfoPage"; // Add this line
import Userpdf from "./components/Userpdf"; // Add this line
const call = "tel: +966556080505";

function App() {
 
 

 
  return (
    <div>
      <AuthProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}  >
          <Navbare /> 
          <div className="fixed-button">
            <button>
              <a className="text-white" href={call}>
                <MdSupportAgent size={30} />
              </a>
            </button>
          </div>
          <Routes>
            {/* <Route
              path="m"
              element={
                <PublicRoute>
                  <Home />
                </PublicRoute>
              }
            /> */}
                        <Route path="/" element={<Homee />} />

            <Route element={< PrivateRoute />}>
              <Route path="/" element={<Homee />} />
              <Route path="/SignUpPage" element={<SignUpPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<div><h1>404 Page not found.</h1></div>} />
              <Route path="/contact_us" element={<Contact_Us />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/automation" element={<Automation />} />
              <Route path="/safety" element={<Safety />} />
              <Route path="security" element={<Security />} />
              <Route path="/connectivity" element={<Connectivity />} />
              <Route path="/support" element={<Support />} />
              <Route path="/Latestnews" element={<LatestNews />} />
              <Route path="/liscence" element={<Liscence />} />
              <Route path="/cabel" element={<Cabel />} />
              <Route path="/phone" element={<Phone />} />
              <Route path="/switch" element={<Switch />} />
              <Route path="/controlunit" element={<Controlunit />} />
              <Route path="/article" element={<Article />} />
              <Route path="/details" element={<Details />} />
              <Route path="/ourclient" element={<Ourclient />} />
              <Route path="/Purchase" element={<Purchase />} />
              <Route path="/home" element={<Home />} />
              <Route path="/forget" element={<LogForgetpassword />} />
              {/* <Route path="/user-info" element={<UserInfoPage />} /> */}
              {/* <Route path="/shoppingcart" element={<ShoppingCart />} />
              <Route path="/continue" element={<Continue />} /> */}
              <Route path="/user-info" element={<UserInfoPage />} />
              <Route path="/Userpdf" element={<Userpdf />} />
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}
export default App;


















// import React from 'react';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import "./App.css";
// import Footer from './components/Footer.js';
// import Navbare from './components/Navbare';
// import Homee from "./pages/Homee";
// import SignUp from "./components/SignUp";
// import { AuthProvider } from "./context/AuthContext";
// import Home from "./components/Home";

// import { useEffect, useState } from 'react';
// import axios from 'axios';

// import Login from "./components/Login";
// import PrivateRoute from "./components/PrivateRoute";
// import PublicRoute from "./components/PublicRoute";
// import Solutions from "./components/Solutions";
// import Automation from "./components/Automation";
// import Safety from "./components/Safety";
// import Security from "./components/Security";
// import Connectivity from "./components/Connectivity";
// import Support from "./components/Support";



// import Contact_Us from "./components/Contact_Us";
// import LatestNews from "./components/LatestNews";
// import Liscence from "./components/Liscence";
// import Cabel from "./components/Cabel";
// import Phone from "./components/Phone";
// import Switch from "./components/Switch";
// import Controlunit from "./components/Controlunit";

// import { initializeApp } from "firebase/app";
 
// import Article from "./components/Article";
// import Ourclient from "./components/Ourclient";
// import Details from './Details';
// import SignUpPage from './SignUpPage';
// import Purchase from './Purchase';
// import LogForgetpassword from "./components/LogForgetpassword";
// import UserInfoPage from "./components/UserInfoPage"; // Add this line
// import Userpdf from "./components/Userpdf"; // Add this line
// import { analytics } from './service/firebase/firebase'; // Update the path accordingly

// function App() {
//   const [firebaseConfig, setFirebaseConfig] = useState(null);

//   useEffect(() => {
//     const fetchFirebaseConfig = async () => {
//       try {
//         const response = await axios.get('http://localhost:8000/firebase-config');
//         setFirebaseConfig(response.data);
//       } catch (error) {
//         console.error('Error fetching Firebase config:', error);
//       }
//     };

//     fetchFirebaseConfig();
//   }, []); // Run once on component mount

//   useEffect(() => {
//     if (firebaseConfig) {
//       const app = initializeApp(firebaseConfig);
//       const analytics = analytics(app);
//       // Other Firebase initialization logic...
//     }
//   }, [firebaseConfig]);
//   return (
//     <div>
//       <AuthProvider>
//         <BrowserRouter  >
//           <Navbare /> {/* Include the Navbar component here */}
//           <Routes>
//             <Route
//               path="m"
//               element={
//                 <PublicRoute>
//                   {/* <Home /> */}
//                 </PublicRoute>
//               }
//             />
//             <Route element={< PrivateRoute />}>
//               <Route path="/" element={<Homee />} />
//               <Route path="/SignUpPage" element={<SignUpPage />} />
//               <Route path="/signup" element={<SignUp />} />
//               <Route path="/login" element={<Login />} />
//               <Route path="*" element={<div><h1>404 Page not found.</h1></div>} />
//               <Route path="/contact_us" element={<Contact_Us />} />
//               <Route path="/solutions" element={<Solutions />} />
//               <Route path="/automation" element={<Automation />} />
//               <Route path="/safety" element={<Safety />} />
//               <Route path="security" element={<Security />} />
//               <Route path="/connectivity" element={<Connectivity />} />
//               <Route path="/support" element={<Support />} />
//               <Route path="/Latestnews" element={<LatestNews />} />
//               <Route path="/liscence" element={<Liscence />} />
//               <Route path="/cabel" element={<Cabel />} />
//               <Route path="/phone" element={<Phone />} />
//               <Route path="/switch" element={<Switch />} />
//               <Route path="/controlunit" element={<Controlunit />} />
//               <Route path="/article" element={<Article />} />
//               <Route path="/details" element={<Details />} />
//               <Route path="/ourclient" element={<Ourclient />} />
//               <Route path="/Purchase" element={<Purchase />} />
//               <Route path="/home" element={<Home />} />
//               <Route path="/forget" element={<LogForgetpassword />} />
//               {/* <Route path="/user-info" element={<UserInfoPage />} /> */}
//               {/* <Route path="/shoppingcart" element={<ShoppingCart />} />
//               <Route path="/continue" element={<Continue />} /> */}
//               <Route path="/user-info" element={<UserInfoPage />} />
//               <Route path="/Userpdf" element={<Userpdf />} />
//             </Route>
//           </Routes>
//           <Footer />
//         </BrowserRouter>
//       </AuthProvider>
//     </div>
//   );
// }
// export default App;