import { Link } from "react-router-dom";
import "./Headsol.css";
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

const titleStyle = {
    fontSize: '2em',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
};
const Headsol = () => {
    const { t, i18n } = useTranslation("global");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    return (
        <>
            <div className="contact-us-solution">
                <header>
                    <div className="hs">
                        <h1 style={titleStyle}>
                            {t("Headsol.Headsoltitle")}
                        </h1>
                    </div>
                    <br />
                    <br />
                </header>
                <div className="background-Sol">
                    <div className="solution-buttons">
                        <Link to="/automation" >
                            <button className="solution-button">
                                {t("Headsol.Headsol1")}
                            </button>
                        </Link>
                        <Link to="/safety" >
                            <button className="solution-button">
                                {t("Headsol.Headsol2")}
                            </button>
                        </Link>
                        <Link to="/security" >
                            <button className="solution-button">
                                {t("Headsol.Headsol3")}
                            </button>
                        </Link>
                        <Link to="/connectivity" >
                            <button className="solution-button">
                                {t("Headsol.Headsol4")}
                            </button>
                        </Link>
                    </div>

                </div>
                <div>
                </div>
            </div>
        </>
    );
};

export default Headsol;
