// About.js
import React, { useState, useEffect } from 'react';
import "./About.css";
import { useTranslation } from 'react-i18next';

const About = () => {
    const [currentBackground, setCurrentBackground] = useState(0);
    const { t, i18n } = useTranslation("global");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    const backgrounds = [
        '/images/telephone main destribution.jpg',
        '/images/Safety.jpg',
        '/images/Police_surveillance_camera.jpg',
        '/images/building.jpg',
        '/images/datacenter.jpg',
        '/images/Types-of-cctv-cameras.jpg',
    ];

    const changeBackground = () => {
        setCurrentBackground((prevBackground) => (prevBackground + 1) % backgrounds.length);
    };
    useEffect(() => {
        const intervalId = setInterval(changeBackground, 3000);
        return () => clearInterval(intervalId);
    }, [changeBackground]);
    const handleButtonClick = (index) => {
        setCurrentBackground(index);
    };
    return (
        <div className="solution-wrapper centered-content">
            <div className='background-image'style={{ direction: getDirection() }}>
                <div className={`background-image ${currentBackground}
             transition`} style={{
                        backgroundImage:
                            `url(${backgrounds[currentBackground]})`
                    }}>
                    <br />
                    <br />     <br />
                    <br />
                    <br /> <b>
                        <h1 className="solution-title">{t("About.Aboutitel")}</h1></b>

                    <div className="friendly-message" style={{ direction: getDirection() }}>
                        {t("About.message")}

                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="centered-box">
                        <div className="text-container">
                            <h1 style={{ direction: getDirection() }}>
                                {t("About.AboutText")}

                            </h1>
                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("About.Text1")}

                            </p>
                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("About.Text2")}

                            </p>
                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("About.Text3")}

                            </p>
                            <p style={{ color: 'black', direction: getDirection() }}>
                                {t("About.Text4")}

                            </p>
                        </div>
                        <br />
                        <div className="buttons-container">
                            {backgrounds.map((_, index) => (
                                <button
                                    key={index}
                                    className={`background-button 
                                    ${index === currentBackground ? 'active' : ''}`}
                                    onClick={() => handleButtonClick(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default About;
