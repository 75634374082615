// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import global_En from "./translation/En/global.json";
// import global_Ar from "./translation/Ar/global.json";
// import i18next from 'i18next';
// import { I18nextProvider } from 'react-i18next';
// i18next.init({
//   interpolation: { escapeValue: false },
//   lng: "en",  // Check if this matches the desired default language
//   resources: {
//     en: {
//       global: global_En,
//     },
//     ar: {
//       global: global_Ar,
//     },
//   },
// });

//  const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <I18nextProvider i18n={i18next}>
//     <App />

//     </I18nextProvider>
//   </React.StrictMode>
// );
// serviceWorkerRegistration.unregister();


// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import global_En from "./translation/En/global.json";
import global_Ar from "./translation/Ar/global.json";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_En,
    },
    ar: {
      global: global_Ar,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();

