import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { createPortal } from "react-dom";
import { HomePasswordChangeModal, HomeWithdrawalChild } from "./HomeChild";
import { auth } from "../service/firebase/firebase";
import { useAuthContext } from "../context/AuthContext";
import "./home.css";

import Title from "./Title";
import { Button } from "@mui/material";
import { Logout } from "@mui/icons-material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const ModalPortal = ({ children }) => {
  const target = document.querySelector(".modalContainer");
  return createPortal(children, target);
};

const Home = () => {
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);
  const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const handleLogout = () => {
    signOut(auth);
    navigate("/login");
  };

  return (
    <>
      <div className="home-container">
        <Title />
        <div className="modalContainer"></div>
        <p className="user-info">Information about the currently logged in user</p>
        <p className="user-info">email : {user.email}</p>
        <p className="user-info">
          Provider :
          {user.providerData[0].providerId === "password"
            ? "email Login"
            : user.providerData[0].providerId === "google.com"
              ? "Google"
              : "invalid provider"}
        </p>
        <p className="user-info">
          {!user.emailVerified &&
            "Access to you profile is currently restricted as your email address has not completed the authentication process. Kindly refresh the page once the email authentication is successfully finalized."}
        </p>




        <Button
          onClick={handleLogout}
          variant="outlined"
          startIcon={<Logout />}
          sx={{ mr: 1 }}
        >
          Logout
        </Button>
        <Button className="unsubscribe-button"
          onClick={() => setWithdrawalModalOpen(true)}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          unsubscription
        </Button>
        {user.providerData[0].providerId === "password"
          && <Button className="change-password-button"
            onClick={() => setPasswordChangeModalOpen(true)}
            variant="outlined"
            startIcon={<PublishedWithChangesIcon />}
          >
            The password has been changed.          </Button>}
      </div>
      <div >
        {withdrawalModalOpen && (
          <ModalPortal>
            <HomeWithdrawalChild
              setWithdrawalModalOpen={setWithdrawalModalOpen}
            />
          </ModalPortal>
        )}
        {passwordChangeModalOpen && (
          <ModalPortal>
            <HomePasswordChangeModal
              setPasswordChangeModalOpen={setPasswordChangeModalOpen}
            />
          </ModalPortal>
        )}
      </div>
    </>
  );
};
export default Home;
