import getCredential from "../firebase/getCredential";
import { updatePassword } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { issueMsg } from "../common/issueMsg";

export const changePassword = async (oldPassword,newPassword) => {

    const user = auth.currentUser;

    try{
        await getCredential('email',user, oldPassword);
        await updatePassword(user,newPassword);
        issueMsg(`The password has been successfully changed.   ：${user.email}`);
        return true;
      } catch(error) {
        if (error.code === 'auth/wrong-password') {
        issueMsg('The entered password is invalid. ');
        } else {
          issueMsg(`An error occurred during the password change process.
          ${error.code}`);
        }
        console.log(`Password change failed.   `);
        console.log(user);
        console.error(error);
        return false;
      }
  }

export default changePassword;
