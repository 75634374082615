import { useNavigate } from "react-router-dom";
import { delUser } from "../service/authenticationProcess/delUser";
import changePassword from "../service/authenticationProcess/changePassword";
import "./styles/portal.css";
import { issueMsg } from "../service/common/issueMsg";
import { auth } from "../service/firebase/firebase";

export const HomeWithdrawalChild = ({ setWithdrawalModalOpen }) => {
  const navigate = useNavigate();
  const user = auth.currentUser;

  const handleDelUserClick = () => {
    delUser().then((res) => {
      setWithdrawalModalOpen(false);
      res && user.providerData[0].providerId === "password"
        ? navigate("/SignUp")
        : navigate("/Login");
    });
  };
  return (
    <div className="modal">
      <div className="modal__content">
        <p>Are you sure you want to unsubscribe?</p>
        <div>
          <button type="button" onClick={handleDelUserClick}>
          Do you really want to unsubscribe?
          </button>
        </div>
        <div>
          <button type="button" onClick={() => setWithdrawalModalOpen(false)}>
          I won't unsubscribe after all
          </button>
        </div>
      </div>
    </div>
  );
};

export const HomePasswordChangeModal = ({ setPasswordChangeModalOpen }) => {
  const handleChangePassword = async (e) => {
    e.preventDefault();
    const [oldPassword, newPassword, sameNewPassword] = e.target.elements;
    if (!oldPassword.value) {
      issueMsg("The previous password has not been entered");
      return;
    } else if (!newPassword.value) {
      issueMsg("The new password has not been entered");
      return;
    } else if (!sameNewPassword.value) {
      issueMsg("The new password confirmation is missing");
      return;
    }
    if (newPassword.value !== sameNewPassword.value) {
      issueMsg(
        "The new password and its confirmation do not match"
      );
      return;
    }

    await changePassword(oldPassword.value, newPassword.value);
    setPasswordChangeModalOpen(false);
  };
  return (
    <div className="modal">
      <div className="modal__content">
        <form onSubmit={handleChangePassword}>
          <div>
            <label htmlFor="oldPassword">
            Current/Existing password
                          <input type="password" name="oldPassword" id="oldPassword" />
            </label>
          </div>
          <div>
            <label htmlFor="newPassword">
            New password
                          <input type="password" name="newPassword" id="newPassword" />
            </label>
          </div>
          <div>
            <label htmlFor="sameNewPassword">
            Enter the new password again              <input
                type="password"
                name="sameNewPassword"
                id="sameNewPassword"
              />
            </label>
          </div>
          <div>
            <button>Change the password</button>
          </div>
        </form>
        <button onClick={() => setPasswordChangeModalOpen(false)}>
        Cancel
        </button>
      </div>
    </div>
  );
};
