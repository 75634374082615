import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./UserInfoPage.css";
import { useAuthContext } from "../context/AuthContext";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const UserInfoPage = () => {
  const location = useLocation();
  const { cartItems } = location.state;
  const { user } = useAuthContext();
  const [MapUrl, setMapUrl] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isEditing, setIsEditing] = useState(true); // Declare setIsEditing state
  const [isConfirmed, setIsConfirmed] = useState(false); // Added state for confirmation

  useEffect(() => {
  }, [cartItems]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ latitude, longitude });
        setMapUrl(`https://www.google.com/maps?q=${latitude},${longitude}`);
      },
      (error) => {
        console.error('Error getting location:', error.message);
      }
    );
  }, []);
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gender: "",
    birthdate: "",
  });
  const db = firebase.firestore();
  const userCollection = db.collection('users');
  const UploadData = async () => {
    try {
      const userId = user.uid;
      const userDocRef = userCollection.doc(userId);
      await userDocRef.set({
        name: formData.name,
        email: user.email,
        phoneNumber: formData.phoneNumber,
        gender: formData.gender,
        birthdate: formData.birthdate,
        // cartItems: cartItems.map(item => ({ id: item.id, quantity: item.quantity })),
        // totalQuantity,
        // totalPrice,
        // MapUrl: MapUrl,
      });
      setIsEditing(false); // Disable editing after confirming
      setIsConfirmed(true); // Set confirmation status to true

      console.log('Data uploaded successfully!');
    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };



  const SendOrder = async () => {

    try {
      const ordersCollection = db.collection('orders');
      await ordersCollection.add({
        userId: user.uid,
        userName: formData.name,
        userEmail: user.email,
        userPhoneNumber: formData.phoneNumber,
        userGender: formData.gender,
        userBirthdate: formData.birthdate,
        cartItems: cartItems.map(item => ({ id: item.id, quantity: item.quantity })),
        totalQuantity,
        totalPrice,
        mapUrl: MapUrl,
      });

      console.log('Order data uploaded successfully!');
    } catch (error) {
      console.error('Error uploading order data:', error);
    }




  };

  const handleConfirmButtonClick = () => {
    setIsEditing(false); // Disable editing after confirming
    UploadData(); // Upload data after confirmation
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
// console.log( "this is my user "+user.uid)

  return (
    <div className="container">
      <div className="info-container">
        <form>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
            disabled={!isEditing}
            className={isEditing ? '' : 'disabled-input'} // Add a class for styling
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={user.email}
            onChange={handleInputChange}
            disabled={!isEditing}
            className={isEditing ? '' : 'disabled-input'} // Add a class for styling
          />

          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            disabled={!isEditing}
            className={isEditing ? '' : 'disabled-input'}
          />

      

       

          <button type="button" onClick={UploadData} disabled={!isEditing}>
            Are you sure ?
          </button>

          {
            isConfirmed && (
              <div>

                <Link
                  to=
                  "/Userpdf"
                  state={{ cartItems: cartItems }} total={{ totalPrice }}
                  formData={{ formData }}
                >
                  <button type="button" onClick={SendOrder}>
                    Send
                  </button>
                </Link>
              </div>
            )}




        </form>
      </div>
      <h2>User Information</h2>
      <div className="product-grid">
        {cartItems.map((item) => (
          <div className="user-info" key={item.id}>
            <p>Product Name: {item.name}</p>
            <p>Price: {item.price}</p>
            <p>Quantity: {item.quantity}</p>
          </div>
        ))}
        <div className="user-info">
          <p>Total Quantity: {totalQuantity}</p>
          <p>Total Price: ${totalPrice.toFixed(2)}</p>
        </div>
      </div>

    </div>
  );
}

export default UserInfoPage;
