import React, { useEffect, useState } from "react";
import "./Security.css";
import Headsol from "./Headsol";
import { useTranslation } from 'react-i18next';

const Security = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div>
            <div>
                <Headsol />
            </div>
            <div>
                <h1 className="titleh" style={{ direction: getDirection() }}>
                    {t("security.mainTitle")}

                </h1>
                <br />
                <br /> <br />
                <br />
                <h3 className="titlem" style={{ direction: getDirection() }}>
                    {t("security.subTitle")}

                </h3>
                <br />
                <br />
                <div>
                    <div className="col-md-6" style={{ direction: getDirection() }}>
                        <div className="automationtxt colmd6">
                            <p style={{ color: "black" }}>{t("security.intrusionDetectionDescription")}

                                <br />
                                <br /> <br />
                                <br />
                                {t("security.intrusionDetectionPortfolio")}
                                <br />
                                <br />
                                {t("security.intrusionDetectionList1")}

                                <br /> 
                                {t("security.intrusionDetectionList2")}

                                <br />
                                {t("security.intrusionDetectionList3")}

                                <br /> 
                                {t("security.intrusionDetectionList4")}

                                <br /> 
                                {t("security.intrusionDetectionList5")}

                                <br />
                                {t("security.intrusionDetectionList6")}   <br />
                                <br />     <br />
                                {t("security.termination")}
                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/door.jpg"
                            alt="Phone"
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>
            <div>
                <br />
                <br />
                <br />
                <h3 className="titlem"  style={{ direction: getDirection() }}>{t("security.videoSurveillance")}</h3>
                

                <br />
                <br />
                <div>
                <div className="col-md-6" style={{ direction: getDirection() }}>

                        <div className="automationtxt colmd6 ">
                            <p style={{ color: "black" }}>
                                {t("security.videoSurveillanceDescription")}

                                <br />
                                <br />
                                {t("security.videoSurveillanceUsage")}

                                <br />
                                <br />
                                {t("security.videoSurveillanceObjective")}

                                <br />
                                <br />
                                {t("security.videoSurveillanceFootage")}

                                <br />
                                <br />
                                {t("security.videoSurveillanceTechnology")}

                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/cctv_security.jpg"
                            alt="switch"
                            className="cctv"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>
            <div>
                <br />
                <br />
                <br />
                <h3 className="titlem"  style={{ direction: getDirection() }}> {t("security.accessControl")}</h3>
                <br />   
                <br />
                <div>
                <div className="col-md-6" style={{ direction: getDirection() }}>
                        <div className="automationtxt colmd6">
                            <p style={{ color: "black" }}>
                                {t("security.accessControlDescription")}

                                <br />
                                <br /> <br />
                                {t("security.accessControlExplanation")}

                                <br />
                                <br />
                                {t("security.accessControlJASO")}

                                <br />
                                <br /> 
                                {t("security.access")}

                                <br />
                                <br />
                                {t("security.accessControlObjective")}

                            </p>
                        </div>
                    </div>
                    <div className="colmd6">
                        <img
                            src="assets/Access-Control-System.png"
                            alt="switch"
                            className="img-fluid"
                        />
                    </div>
                    <div className="bottom-line"></div>
                </div>
            </div>
        </div>

    );
};

export default Security;
