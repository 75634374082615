import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { isSignInWithEmailLink } from "firebase/auth";

import { Input, Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";

import loginFirebase from "../service/firebase/loginFirebase";
import sendEmailLink from "../service/firebase/sendEmailLink";
import { auth, googleProvider } from "../service/firebase/firebase";
import submitPasswordResetEmail from "../service/firebase/submitPasswordResetEmail";
import Title from "./Title";
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  let goHome = false;

  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      email = window.prompt("Please enter the email address");
    }
    loginFirebase("mailLink", email).then((ret) => ret && (goHome = true));
  } else {

  }
  const handleLogin = (e) => {
    e.preventDefault();
    const [loginEmail, loginPassword] = e.target.elements;
    loginFirebase("email", loginEmail.value, loginPassword.value).then(
      (ret) => ret && navigate("/home")//////////////////////////////////////////////
    );
  };

  const handleMailLink = (e) => {
    e.preventDefault();
    const [emailLink] = e.target.elements;
    sendEmailLink(emailLink.value);
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    const [resetEmail] = e.target.elements;
    submitPasswordResetEmail(resetEmail.value);
  };

  const handleGoogleLogin = async (e) => {
    loginFirebase("google", googleProvider).then((ret) => ret && navigate("/home"));/////////////////////////////////////////////
  };

  return (
    <>
      {goHome ? (
        navigate("/home")
      ) : (
        <div className="login-container">
          <div className="login-form-container">
            <div className="titel">  <h1>Login</h1></div>
            <div className="login-type">
              <form onSubmit={handleLogin} className="login-form">
                <div className="user-box">
                  <Input
                    id="email"
                    name="email"
                    placeholder="email"
                    type="email"
                  />
                </div>
                <div className="user-box">
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="password"
                  />
                </div>
                <div style={{ margin: "20px 0" }}>
                  <Button
                    variant="outlined"
                    startIcon={<LoginIcon />}
                    type="submit"
                  >
                    Login
                  </Button>
                </div>
              </form>

              <Link to="/signup">
                <Button variant="text" color="primary">
                I do not currently possess an account.
                </Button>
              </Link>

              <Link to="/forget">
                <Button variant="text" color="primary">
                  Forget your password
                </Button>
              </Link>
            </div>

            <div className="login-type">
              <h3 >Sign in with Google</h3>
              <img 
                onClick={handleGoogleLogin}
                src={`${process.env.PUBLIC_URL}/btn_google_signin_light_focus_web.png`}
                alt="Google Login with icon"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default Login;
