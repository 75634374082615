import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import './CabelList.css';
import DetailPage from './Details';
import { Link } from 'react-router-dom';

const hoverEasing = 'cubic-bezier(0.23, 1, 0.02, 1)';
const returnEasing = 'cubic-bezier(0.05, 0.05, 0.05, 0.05)';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
if (process.env.NODE_ENV === 'development') {
  console.log('Firebase App:', app);
}
const CardWrap = styled.div` 
  margin: 10px;
  transform: perspective(800px);
  transform-style: preserve-3d;
  cursor: pointer;
  background-color: transparent;
  background-image: linear-gradient(to bottom, transparent 0%);
  &:hover {
    .card-info {
      transform: translateY(0);
    }
    .card-info p {
      opacity: 1;
      transition: 0.1s ${hoverEasing};
    }
    .card-info:after {
      transition: 5s ${hoverEasing};
      opacity: 1;
      transform: translateY(0);
    }
    
    .card {
      transition: 0.6s ${hoverEasing}, box-shadow 2s ${hoverEasing};
      box-shadow: rgba(255, 255, 255, 0.2) 0 0 40px 5px,
        rgba(255, 255, 255, 1) 0 0 0 1px,
        rgba(0, 0, 0, 0.66) 0 30px 60px 0,
        inset #333 0 0 0 5px;
    }
  }
`;

const StyledCard = styled.div`
  position: relative;
  flex: 0 0 258px;
  width: 240px;
  height: 340px;
  background-color: #333;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.66) 0 30px 60px 0,
    inset #333 0 0 0 1px,
    inset rgba(255, 255, 255, 0.5) 0 0 0 6px;
  transition: 1s ${returnEasing};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const CardBg = styled.div``;

const CardInfo = styled.div`
  padding: 20px;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  color: #fff;
  transform: translateY(40%);
  transition: 0.3s 1.3s cubic-bezier(0.05, 0.01, 0.05, 1),
    opacity 3s 1s cubic-bezier(0.05, 0.01, 0.05, 1);

p {
  opacity: 0;
  color: #000; /* Set text color to black */
  text-shadow: 0 2px 3px rgba(255, 255, 255, 1); /* Set text shadow to white */
  transition: opacity 0.3s cubic-bezier(0.05, 0.01, 0.5, 1) 1.3s;
}


  * {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient( rgba(0, 0, 0, 12) 100%);
    background-blend-mode: overlay;
    opacity: 12;
    transform: (100%);
    transition: 3s 1s cubic-bezier(0.05, 0.01, 0.05, 1);
  }
`;

const Button = styled.button`
background-color: rgba(128, 128, 128, 0.5); /* Adjust the last value (alpha) for transparency */
color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #3498db; /* Change to your preferred hover color */
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`;


const Card = ({ backgroundImage, children, addToCart, Cabel }) => {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [mouseLeaveDelay, setMouseLeaveDelay] = useState(null);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (cardRef.current) {
        const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = cardRef.current;
        const x = e.pageX - offsetLeft - offsetWidth / 2;
        const y = e.pageY - offsetTop - offsetHeight / 2;
        setMouseX(x);
        setMouseY(y);
      }
    };
    const handleMouseEnter = () => {
      clearTimeout(mouseLeaveDelay);
    };
    const handleMouseLeave = () => {
      clearTimeout(mouseLeaveDelay);
      const delay = setTimeout(() => {
        setMouseX(0);
        setMouseY(0);
      }, 1000);
      setMouseLeaveDelay(delay);
    };
    if (cardRef.current) {
      cardRef.current.addEventListener("mousemove", handleMouseMove);
      cardRef.current.addEventListener("mouseenter", handleMouseEnter);
      cardRef.current.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener("mousemove", handleMouseMove);
        cardRef.current.removeEventListener("mouseenter", handleMouseEnter);
        cardRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [mouseLeaveDelay]);

  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };
  const cardBgTransform = {};
  const message = 'Hello from PageA';

  return (
    <CardWrap className="card-wrap" ref={cardRef}>
      <StyledCard className="card" style={cardStyle}>
        <CardBg className="CardBg" style={cardBgTransform}></CardBg>
        <CardInfo className="card-info">{children}</CardInfo>
        <Button onClick={() => addToCart({ ...Cabel, type: 'Cabel' })}> Add to Cart</Button>
        <Link to="/details" state={{ data: Cabel.name, type: 'Cabel' }}>
          <button class="button">View Details</button>
        </Link>
      </StyledCard>
    </CardWrap>
  );
};

const CabelList = ({ CabelData, addToCart }) => {

  const [Cabels, setCabels] = useState([]);

  useEffect(() => {
    const fetchCabels = async () => {
      try {
        const CabelCollection = await getDocs(collection(firestore, 'Cabel'));
        const CabelData = CabelCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCabels(CabelData);
      } catch (error) {
        console.error('Error fetching Cabels:', error);
      }
    };

    fetchCabels();
  }, [firestore]);

  return (
    <div>
      <h2>Cabel List</h2>
      <div class="grid-container">

        {
          Cabels.map((Cabel) => (

            <div key={Cabel.id}>
              <Card backgroundImage={Cabel.image1} addToCart={addToCart} Cabel={Cabel}>
                <CardInfo>
                  <p>{Cabel.name}</p>
                  <p>${Cabel.price}</p>
                </CardInfo>
              </Card>

            </div>
          ))
        }
      </div>
    </div>

  );
};

export default CabelList;
