import React, { useState } from 'react';
import { useAuthContext } from "./context/AuthContext";
import { useNavigate, useLocation } from 'react-router-dom';
import PhoneList from './PhoneList';
import SwitchList from './SwitchList';
import CabelList from './CabelList';
import ControlunitList from './ControlunitList';
import LisenceList from './LisenceList';
import ShoppingCart from './ShoppingCart';
import Bill from './Bill';
import './Purchase.css';
import { Link } from "react-router-dom";

const Purchase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const [cartItems, setCartItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('phone');

  const addToCart = (item) => {
    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find((cartItem) => cartItem.id === item.id);

      if (existingItem) {
        return prevCartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
        );
      } else {
        return [...prevCartItems, { ...item, quantity: 1 }];
      }
    });
  };

  const updateQuantity = (itemId, newQuantity) => {
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.id === itemId ? { ...cartItem, quantity: newQuantity } : cartItem
    );
    setCartItems(updatedCartItems);
  };

  const removeItem = (itemId) => {
    const updatedCartItems = cartItems.filter((cartItem) => cartItem.id !== itemId);
    setCartItems(updatedCartItems);
  };

  const getTotal = () => {
    return cartItems.reduce((total, cartItem) => total + cartItem.price * cartItem.quantity, 0);
  };


  // const handleContinueClick = () => {
  //   const all = { user, cartItems }; // Combine user and cartItems into a single object
  //   navigate('/user-info'); // Pass the combined data to the user-info page
  // };
  

  const renderCartItem = (item) => {
    return (
      <div className="cart-item" key={item.id}>
        <div className="cart-item-info">
          <img src={item.image1} alt={`Product: ${item.name}`} className="cart-item-image" />
          <div>
            <p className="cart-item-name">{item.name}</p>
            <p className="cart-item-quantity">Quantity: {item.quantity}</p>
          </div>
        </div>
        <div className="cart-item-actions">
          <button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
          <button onClick={() => updateQuantity(item.id, item.quantity - 1)}>-</button>
          <button onClick={() => removeItem(item.id)}>Remove</button>
        </div>
      </div>
    );
  };

  const renderPage = () => {
    switch (selectedCategory) {
      case 'phone':
        return <PhoneList addToCart={addToCart} />;
      case 'switch':
        return <SwitchList addToCart={addToCart} />;
      case 'cabel':
        return <CabelList addToCart={addToCart} />;
      case 'controlunit':
        return <ControlunitList addToCart={addToCart} />;
      case 'lisence':
        return <LisenceList addToCart={addToCart} />;
      default:
        return null;
    }
  };

  return (
    user.emailVerified ? (
      <div className="app-container">
        <div>
          <header>{/* <h1>React E-Commerce App</h1> */}</header>
          <main>
            <div className="navlist">
              <label htmlFor="category">Select a category:</label>
              <select
                id="category"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="phone">Phones</option>
                <option value="switch">Switches</option>
                <option value="cabel">Cables</option>
                <option value="controlunit">Control Units</option>
                <option value="lisence">Licenses</option>
              </select>
            </div>
            {renderPage()}
            <div className="shopping-cart-container">
              {cartItems.length === 0 ? (
                <p>Your cart is empty. Explore our products to find something you like!</p>
              ) : (
                cartItems.map((item) => renderCartItem(item))
              )}
              <div className="cart-summary">
                <p className="total">Total: ${getTotal()}</p>
              </div>
             
              <Link  className="continue-btn" to="/user-info" state={{ cartItems: cartItems }}  total={getTotal()}>Continue</Link>

            </div>

            {/* <Bill cartItems={cartItems} total={getTotal()} /> */}
          </main>
          <footer></footer>
        </div>
      </div>
    ) 
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    : (
      <div className="user-info-container">
        <div className="modalContainer"></div>
        <p className="user-info">
          <strong>Information about the currently logged in user</strong>
        </p>
        <p className="user-info">
          <strong>email </strong> : {user.email}
        </p>
        <p className="user-info">
          <strong>Provider :</strong>
          {user.providerData[0].providerId === "password"
            ? "email Login"
            : user.providerData[0].providerId === "google.com"
              ? "Google"
              : "invalid provider"}
        </p>
        <p className="user-info">
          {!user.emailVerified &&
            "Access to Shop is currently restricted as your email address has not completed the authentication process. Kindly refresh the page once the email authentication is successfully finalized."}
        </p>
      </div>
    )
  );
};

export default Purchase;