import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { isSignInWithEmailLink } from "firebase/auth";

import { Input, Button } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import EmailIcon from "@mui/icons-material/Email";

import loginFirebase from "../service/firebase/loginFirebase";
import sendEmailLink from "../service/firebase/sendEmailLink";
import { auth, googleProvider } from "../service/firebase/firebase";
import submitPasswordResetEmail from "../service/firebase/submitPasswordResetEmail";
import Title from "./Title";
import './LogForgetpassword.css';

const LogForgetpassword = () => {
    const navigate = useNavigate();
    let goHome = false;

    if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
            email = window.prompt("Please enter the email address");
        }
        loginFirebase("mailLink", email).then((ret) => ret && (goHome = true));
    } else {

    }
    const handleLogin = (e) => {
        e.preventDefault();
        const [loginEmail, loginPassword] = e.target.elements;
        loginFirebase("email", loginEmail.value, loginPassword.value).then(
            (ret) => ret && navigate("/home")//////////////////////////////////////////////
        );
    };

    const handleMailLink = (e) => {
        e.preventDefault();
        const [emailLink] = e.target.elements;
        sendEmailLink(emailLink.value);
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        const [resetEmail] = e.target.elements;
        submitPasswordResetEmail(resetEmail.value);
    };

    const handleGoogleLogin = async (e) => {
        loginFirebase("google", googleProvider).then((ret) => ret && navigate("/home"));/////////////////////////////////////////////
    };

    return (
        <>
            {goHome ? (
                navigate("/home")////////////////////////////////////////////////////////
            ) : (
                <div className="bas">
                    <div className="login-type">
                    <div className="titel">  <h1>Password Recovery</h1></div>

                        <form onSubmit={handleMailLink} className="login-form">
                            Click here to log in via email link              <div>
                                <label htmlFor="emailLink">email address: </label>
                                <Input
                                    type="email"
                                    id="emailLink"
                                    name="emailLink"
                                    placeholder="email"
                                />
                            </div>
                            <div style={{ margin: "5px 0" }}>
                                <Button
                                    variant="outlined"
                                    endIcon={<EmailIcon />}
                                    type="submit"
                                >
                                    Email sent
                                </Button>
                            </div>
                        </form>
                        <form onSubmit={handlePasswordReset}>
                            Can't Remember? Reset Password
                            <div>
                                <label htmlFor="passwordRest">Enter your email here: </label>
                                <Input
                                    type="email"
                                    id="passwordRest"
                                    name="passwordRest"
                                    placeholder="email"
                                />
                            </div>
                            <div style={{ margin: "5px 0" }}>
                                <Button
                                    variant="outlined"
                                    endIcon={<EmailIcon />}
                                    type="submit"
                                >Emailsent
                                </Button>
                            </div>
                        </form>
                        <div className="registration-section">
                            <p>Don't have an account yet?</p>
                            <Link to={"/signup"}>
                                <Button variant="outlined" color="primary">
                                    Register Here
                                </Button>
                            </Link>
                        </div>

                    </div>

                </div>
            )}
        </>
    );
};

export default LogForgetpassword;
