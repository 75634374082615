import {
  deleteUser,
} from "firebase/auth";
import { auth, googleProvider } from "../firebase/firebase";
import getCredential from "../firebase/getCredential";
import { issueMsg } from "../common/issueMsg";
import loginFirebase from "../firebase/loginFirebase";

export const delUser = async () => {
  const user = auth.currentUser;
  let loginProvider;

  try {
     if (user.providerData[0].providerId === "password") {
      loginProvider = "email";
    } else if (user.providerData[0].providerId === "google.com") {
      loginProvider = "google";
      
      const currentLogonUserEmail = user.email;
      issueMsg(
        "Please log in again with your currently logged-in Google ID",
        currentLogonUserEmail
      );
      try {
     
        await loginFirebase("google",googleProvider);
        const newUser = auth.currentUser;
        const newUserEmail = newUser.email;
        await deleteUser(newUser);
        if (newUserEmail !== currentLogonUserEmail) {
          issueMsg(
            "The Google ID logged in again is different. Please log in again and retry the unsubscription process. Incorrect login email:",
            newUserEmail
          );
          return false;
        }
        issueMsg("Unsubscription process is complete.", newUser.email);
        return true;
      } catch (error) {
        if (error.code === "auth/popup-closed-by-user") {
          issueMsg(
            "Login with Google ID for unsubscription process was canceled"
          );
          return false;
        } else {
          console.log(error.code);
          issueMsg(error.message);
          return false;
        }
      }
     } else {
      issueMsg(
        "An unauthorized provider was specified during the unsubscription process。",
        user.providerData[0].providerId
      );
      return false;
    }
    const result = await getCredential(loginProvider, user);
    if (!result) {
      return false;
    }
    await deleteUser(user);
    issueMsg("Unsubscription process is complete.", user.email);
    return true;
  } catch (error) {
    if (error.code === "auth/wrong-password") {
      issueMsg("The entered password is incorrect。");
    } else {
      issueMsg(`An error occurred during the unsubscription process`, error.code);
    }
    console.log(`Unsubscription failed`);
    console.log(user);
    console.log(error);
    console.log(error.message);
    console.log(error.code);
    return false;
  }
};
