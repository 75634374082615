import React, { useEffect, useState } from "react";
import "./Ourclient.css";
import { useTranslation } from 'react-i18next';

const titleStyle = {
    fontSize: '2em',  // Adjust the size as needed
    color: '#333',    // Set your preferred text color
    fontFamily: 'Arial, sans-serif',  // Specify a friendly font-family
};

const items = [
    { id: 1, src: "assets/m (7).jpg", titel: "BRC Industrial (Saudi) Ltd ." },
    { id: 2, src: "assets/sdcapp_logo.jpg", titel: "SDC Application ." },
    { id: 3, src: "assets/Artboard-7@4x-1.png", titel: "Alsulaiman Group (ASG) ." },


    { id: 5, src: "assets/logo.jpg", titel: "European Medical Center ." },
    { id: 6, src: "assets/psmmc.jpg", titel: ".مستشفى الأمير سلطان للقوات المسلحة" },


    { id: 7, src: "assets/adadaKabbani .jpg", titel: "Adada & Kabbani ." },
    { id: 8, src: "assets/m (8).jpg", titel: "Qimmat Al Fakher Co." },
    { id: 9, src: "assets/logo-nebtha.jpg", titel: ".وقف الملك عبد العزيز للعين العزيزية" },

    { id: 10, src: "assets/nesma.jpg", titel: "Nesma Orbit." },
    { id: 11, src: "assets/SAWARY.jpg", titel: "Sawary ." },
    { id: 12, src: "assets/m (3).jpg", titel: "D O M ." },

    { id: 13, src: "assets/mbl.jpg", titel: "Modern Building Leaders." },
    { id: 14, src: "assets/Desar Factory.jpg", titel: "Desar Factory." },
    { id: 15, src: "assets/asyad.jpg", titel: "Asyad Holding Group." },
    { id: 16, src: "assets/new-design.jpg", titel: "ALDREES Petroleum and Transport Services Company." },

    // Add more items as needed
];
const Ourclient = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    return (
        <div className="Supportcontainer"style={{ direction: getDirection() }}>
            <header>
                <h1 style={titleStyle}>{t("Ourclient.Ourtitle")}</h1>
                <br />
                <br />
                <br />
            </header>
            <div className="ourclientB">
                <div className="friendly-coverSupport">
                    {t("Ourclient.newspart1")}                 <br />
                    {t("Ourclient.newspart2")}                </div>
            </div>
            <div >
                <br />
                <br />
            </div>
            <div className="automationtxt col-md-6 text-center">
                <p>
                    {t("Ourclient.future")}
                    <br />
                </p>
            </div>

            <div className="grid-containerClient">
                {items.map((item) => (
                    <div key={item.id} className="grid-item">
                        <img src={item.src} alt={`item-${item.id}`} className="item-image" />
                        <p className="item-caption"><h1><b>{item.titel}</b></h1></p>
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Ourclient;



